const useSeekMessages = async (props: {
  contact: number
  instanceId: number
  firstMessageId: number
  seekMessageId?: number
}) => {
  const { contact, instanceId, firstMessageId, seekMessageId } = props
  const response = await window.axios.get<{ path: string }>(
    route('v2.message.seek', { contact }),
    {
      params: {
        instanceId,
        firstMessageId,
        seekMessageId,
      },
    },
  )

  const r = ref<Partial<IPChat.ChatMessage>[]>([])
  const link = response.data.path
  const fromS3 = await window.axios.get<Partial<IPChat.ChatMessage>[]>(link)
  r.value = fromS3.data

  return r
}

export default useSeekMessages
